/*
 * Copyright by Intland Software
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information
 * of Intland Software. ("Confidential Information"). You
 * shall not disclose such Confidential Information and shall use
 * it only in accordance with the terms of the license agreement
 * you entered into with Intland.
 */
import { IFrameWidget } from "./iframe-widget";
import { IFrameWidgetHandlers } from "./iframe-widget-handlers";
import { EventNames } from "@intland/cb-widget-shared";
var IFrameWidgets = /** @class */ (function () {
    function IFrameWidgets() {
        this.selectedItem = undefined;
        this.widgets = [];
    }
    IFrameWidgets.prototype.registerIframe = function (iframe, url, config) {
        var handlers = new IFrameWidgetHandlers(config || {}, iframe, this.selectedItem);
        this.widgets.push(new IFrameWidget(window, iframe, url, handlers));
    };
    IFrameWidgets.prototype.emitItemChange = function (itemId) {
        this.selectedItem = itemId;
        this.widgets = this.widgets.filter(function (widget) { return document.contains(widget.iFrame); });
        this.widgets.forEach(function (widget) {
            widget.setSelectedItem(itemId);
            widget.sendEvent(EventNames.SELECTED_ITEM_CHANGED, { itemId: itemId });
        });
    };
    return IFrameWidgets;
}());
export { IFrameWidgets };
