/*
 * Copyright by Intland Software
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information
 * of Intland Software. ("Confidential Information"). You
 * shall not disclose such Confidential Information and shall use
 * it only in accordance with the terms of the license agreement
 * you entered into with Intland.
 */
import { FunctionNames } from "@intland/cb-widget-shared";
var IFrameWidget = /** @class */ (function () {
    function IFrameWidget(hostWindow, iFrame, url, handlers) {
        var _this = this;
        this.iFrame = iFrame;
        this.url = url;
        this.handlers = handlers;
        this.selectedItem = null;
        if (!(url instanceof URL)) {
            throw new Error('url must be a URL instance');
        }
        hostWindow.addEventListener('message', function (event) { return _this.receive(event); });
        this.iFrame.src = this.url.toString();
    }
    IFrameWidget.prototype.receive = function (event) {
        var _this = this;
        if (event.source !== this.iFrame.contentWindow)
            return;
        if (event.origin !== this.url.origin) {
            throw new Error('origin mismatch ' + event.origin + ' vs ' + this.url.origin);
        }
        if (!isExchangeMessageBody(event.data)) {
            throw new Error('exchange payload has unknown function: ' + event.data.func);
        }
        var handler = this.handlers[event.data.func];
        if (!handler)
            throw new Error('unhandled type: ' + event.data.func);
        handler.call(this.handlers, event.data).then(function (payload) {
            var message = {
                exchangeId: event.data.exchangeId,
                func: event.data.func,
                type: 'RPC',
                widgetId: event.data.widgetId,
                payload: payload
            };
            event.source.postMessage(message, _this.url.origin);
        });
    };
    IFrameWidget.prototype.sendEvent = function (event, payload) {
        if (!this.iFrame.contentWindow) {
            throw new Error('iFrame does not have a contentWindow');
        }
        var message = {
            type: 'event',
            event: event,
            payload: payload
        };
        this.iFrame.contentWindow.postMessage(message, this.url.origin);
    };
    IFrameWidget.prototype.setSelectedItem = function (item) {
        this.selectedItem = item;
    };
    return IFrameWidget;
}());
export { IFrameWidget };
function isExchangeMessageBody(message) {
    return Object.values(FunctionNames).indexOf(message.func) > -1;
}
